<template>
  <v-row justify="start">
    <v-dialog v-model="dialogFeed" scrollable persistent max-width="500px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Create post</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('false')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <div class="px-2 pt-2 d-flex align-center">
          <h4></h4>
        </div>
        <v-card-text class="px-2">
          <v-container>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-textarea
                  name="ok"
                  hide-details
                  autofocus
                  v-model="status"
                  label="Write something!"
                  auto-grow
                  flat
                  solo
                ></v-textarea>
                <v-card v-if="picture" elevation="1" style="width: max-content">
                  <div class="d-flex flex-column">
                    <section class="d-flex justify-end temp">
                      <v-btn fab x-small color="red" depressed class="over" dark
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                    </section>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="postFeed">
            <v-icon class="mr-2">mdi-tooltip-check</v-icon>
            Post</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

// import Swal from "sweetalert2";

export default {
  name: "createfeed",
  props: ["dialogFeed"],
  computed: {
    ...mapState({
      profile: state => state.campus.myProfile,
      env: state => state.API_URL
    })
  },
  data() {
    return {
      status: "",
      privacy: {
        icon: "mdi-earth",
        txt: "Public"
      },
      picture: null,
      filter: [
        { state: "item 1", val: "Nama" },
        { state: "item 2", val: "Campus" }
      ]
    };
  },

  methods: {
    postFeed() {
      this.$store
        .dispatch("feeds/createFeeds", { content: this.status })
        .then(data => {
          console.log(data);
          this.$emit("false");
          this.status = "";
        });
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
</style>
