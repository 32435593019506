<template>
  <div class="_bg-default _80w _100vh">
    <div v-if="feed" class="d-flex">
      <div class="_100top _60-w pa-3">
        <v-card class="radius-card pa-3">
          <v-btn
            rounded
            depressed
            block
            class="d-flex justify-start grey--text"
            @click="dialogFeed = true"
            ><v-icon class="mr-2">mdi-pencil-box-outline</v-icon> Mulai buat
            postingan</v-btn
          >
        </v-card>

        <div class="pa-2 mt-2 navigate__">
          <v-row>
            <v-col cols="6" class="pa-1 d-flex align-center">
              <v-card
                class="radius-card pa-2 text-center"
                width="100%"
                flat
                to="/psikolog/forum"
              >
                <b>Forum</b>
              </v-card>
            </v-col>
            <v-col cols="6" class="pa-1 d-flex align-center">
              <v-card
                class="radius-card pa-2 text-center white--text"
                color="blue"
                width="100%"
                flat
              >
                <b>Feeds</b>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div class="d-flex flex-column align-center mt-10" v-if="!feed.length">
          <img src="../../assets/img/404.svg" height="200px" alt="" />
          <h3>No Feeds Available</h3>
        </div>
        <div v-else>
          <div class="my-4" v-for="(q, idx) in feed" :key="`${idx}key`">
            <v-card class="radius-card" v-if="q.id">
              <section class="pa-3">
                <div class="d-flex">
                  <v-avatar class="mr-3">
                    <img
                      :src="
                        q.relationships[0].user.data.photo_profile
                          ? `${env}/upload/photo_profile/${q.user_id}/${q.relationships[0].user.data.photo_profile}`
                          : `${env}/images/logo.png`
                      "
                      class="img-fit"
                      alt=""
                    />
                  </v-avatar>
                  <div>
                    <h4>{{ q.relationships[0].user.data.nama_lengkap }}</h4>
                    <small class="grey--text">{{
                      $date(q.created_at).fromNow()
                    }}</small>
                  </div>
                </div>
              </section>
              <section class="px-5">
                <p>{{ q.content }}</p>
              </section>
              <v-card-actions @click="toView(q.id)">
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="blue"
                  v-if="q.relationships[1].comments.data.length"
                >
                  {{ q.relationships[1].comments.data.length }}
                  <v-icon>mdi-comment</v-icon>
                </v-btn>
                <v-btn
                  text
                  color="blue"
                  v-if="!q.relationships[1].comments.data.length"
                >
                  <v-icon>mdi-comment</v-icon>
                </v-btn>

                <v-btn
                  text
                  color="blue"
                  v-if="q.relationships[2].like.data < 1"
                >
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn text color="blue" v-if="q.relationships[2].like.data">
                  {{ q.relationships[2].like.data }}
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
          <CreateFeed @false="closeDialog" v-bind:dialogFeed="dialogFeed" />
          <div v-if="meta.total > 10">
            <v-card class="my-5" elevation="1">
              <v-btn
                block
                color="purple"
                dark
                @click="plusPage"
                v-if="!loading && visible"
                >Muat Lainnya</v-btn
              >
              <v-btn block color="purple" dark v-if="loading">
                <v-progress-circular
                  :width="5"
                  color="white"
                  indeterminate
                  class="mr-2"
                ></v-progress-circular>
                <b> LOADING ... </b>
              </v-btn>
            </v-card>
          </div>
        </div>
      </div>
      <div class="_recent _100top _40w _100vh">
        <v-col cols="12" md="12">
          <v-card class="radius-card pa-3">
            <h4>Recent Activities</h4>
            <v-list three-line>
              <template v-for="(act, idx) in activity">
                <v-list-item v-if="act" :key="idx">
                  <v-list-item-avatar>
                    <v-img
                      :src="
                        act.photo_profile
                          ? `${env}/upload/photo_profile/${act.causer_id}/${act.photo_profile}`
                          : `${env}/images/logo.png`
                      "
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      v-html="act.nama_lengkap"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="act.description"
                    ></v-list-item-subtitle>
                    <small class="grey--text">{{
                      $date(act.created_at).fromNow()
                    }}</small>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-col>
        <!-- <div v-if="feed" v-observe-visibility="handleScroll"></div> -->
      </div>
    </div>
    <div v-else class="_100top pa-3 d-flex">
      <div class="mr-5 _60-w">
        <v-skeleton-loader
          class="mb-5"
          type="list-item-avatar"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-for="i in 3"
          :key="i"
          class="mb-3"
          type="list-item-avatar, card"
        ></v-skeleton-loader>
      </div>
      <div class="_40w">
        <v-skeleton-loader
          v-for="i in 3"
          :key="i"
          type="article"
          class="mb-1"
        ></v-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CreateFeed from "../../components/Psycholog/Modal/createFeed.vue";

export default {
  components: { CreateFeed },
  data() {
    return {
      dialogFeed: false,
      items: [
        { header: "Today" },
        {
          avatar:
            "https://i.pinimg.com/564x/51/09/a2/5109a27c8d9684202aff5e6e2249cd0b.jpg",
          title: "Brunch this weekend?",
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`
        },
        { divider: true, inset: true }
      ],
      page: 1,
      perPage: 20,
      loading: false,
      visible: true
    };
  },
  computed: {
    ...mapState({
      feed: state => state.feeds.feeds,
      feedDetail: state => state.feeds.feedsDetail,
      role: state => state.role,
      env: state => state.API_URL,
      activity: state => state.feeds.activity,
      last_page_feed: state => state.feeds.last_page_feed,
      meta: state => state.feeds.meta
    })
  },
  mounted() {
    this.fetchFeed();
    this.fetchActivity();
  },
  methods: {
    fetchFeed() {
      let data = {
        page: this.page,
        per_page: this.perPage
      };
      this.$store.dispatch("feeds/listFeeds", data).then(() => {
        if (this.perPage > this.feed.length) {
          this.visible = false;
        }
      });
    },
    fetchActivity() {
      this.$store.dispatch("feeds/listActivity");
    },
    closeDialog() {
      this.dialogFeed = false;
      this.fetchFeed();
    },
    toView(id) {
      this.$router.push(`/psikolog/feed/${id}`);
    },
    plusPage() {
      if (this.perPage > this.feed.length) {
        this.visible = false;
      } else {
        this.perPage += 20;
        this.fetchFeed();
      }
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 60%;
}
._40w {
  width: 40%;
  padding-right: 20px;
}
@media (max-width: 576px) {
  ._60-w {
    width: 100%;
  }
  ._40w {
    display: none;
  }
  ._recent {
    display: none !important;
  }
}
@media (max-width: 768px) {
  ._60-w {
    width: 100%;
  }
  ._40w {
    display: none;
  }
  ._recent {
    display: none !important;
  }
}
</style>
